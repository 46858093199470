import React from "react"
import Pattern from "./pattern"
import Typewriter from 'typewriter-effect'

export default () => {
  return (
    <div className="min-h-1/2 flex flex-col justify-center">
      <main className="">
        <div className="hidden sm:block">
          <Pattern className="mt-40" />
        </div>
        <div className="flex flex-col justify-start min-h-90perc text-center items-center">
          <div className="sm:max-w-3/4 sm:w-3/4 mt-20 w-full">
            <h2 className="text-4xl tracking-tight leading-10 font-extrabold text-gray-900 sm:text-5xl sm:leading-12 md:leading-20 md:text-6xl">
              Sorry, but this page does not exist.<br /> <br />Maybe it<br />got lost on
              <span className="text-indigo-600">
                <Typewriter
                  options={{
                    strings: ['a boring meeting?', 'a disorganized project?', 'an unhelpful coaching?', 'an unstructured 1:1?', 'a call with no video?', 'a terrible training?'],
                    autoStart: true,
                    loop: true,
                    delay: 75,
                    deleteSpeed: 25,
                    wrapperClassName: "inline text-indigo-600",
                  }}
                />
              </span>
            </h2>
          </div>
        </div>
      </main>
    </div>
  )
}
